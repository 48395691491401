import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn } from "../../store/actions/authActions";
import { NavLink, Redirect } from "react-router-dom";

class SignIn extends Component {
  state = {
    email: "",
    password: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state);
  };

  render() {
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to="/student" />;

    return (
      <div className="row">
        <div className="col s12 l5 offset-l3">
          <div className="card">
            <div className="container">
              <form
                onSubmit={this.handleSubmit}
                className="white"
                autoComplete="off"
              >
                <h5 className="teal-text text-darken-1 center">
                  Welcome to freedemy.host
                </h5>
                <div className="input-field">
                  {/* <i className="material-icons prefix small">email</i> */}
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    required
                    onChange={this.handleChange}
                    autoFocus
                  />
                </div>
                <div className="input-field">
                  {/* <i className="material-icons prefix small">password</i> */}
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    required
                    onChange={this.handleChange}
                  />
                </div>
                <div className="input-field center">
                  <div className="row">
                    <div className="col s12 l6 offset-l3">
                      <button
                        className="waves-effect waves-light btn teal darken-1 width100"
                        disabled={!(this.state.email && this.state.password)}
                      >
                        {/* <i className="material-icons small left">lock</i> */}
                        Log in
                      </button>
                    </div>
                  </div>
                  <div className="row center">
                    <div>
                      <NavLink to="/signup">Don't have an account?</NavLink>
                      {" | "}
                      <NavLink to="/forgotpassword">Forgot Password</NavLink>
                    </div>
                    <div></div>
                  </div>
                  <div className="red-text center">{authError}</div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const err = state.auth.authError;
  state.auth.authError = null;
  return {
    authError: err,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
