import React from "react";
// import { connect } from "react-redux";
// import { firestoreConnect } from "react-redux-firebase";
// import { compose } from "redux";
import Subjects from "./Subjects";

const SessionImage = (props) => {
  const subject = Subjects.find((s) => s.name === props.subject);

  const imgFilePath =
    process.env.PUBLIC_URL +
    (subject ? subject.image : "/img/subjects/other.jpg");

  return <img src={imgFilePath} />;
};

// const mapStateToProps = (state) => {
//   return {
//     subjects: state.firestore.ordered.subjects,
//   };
// };

// export default compose(
//   connect(mapStateToProps),
//   firestoreConnect((props) => {
//     return [
//       {
//         collection: "subjects",
//         // where: ["subjectName", "==", props.subject],
//         // storeAs: "subject",
//       },
//     ];
//   })
// )(SessionImage);
export default SessionImage;
