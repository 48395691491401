import React from "react";
import ReactPlayer from "react-player";

const Videos = () => {
  return (
    <div className="row grey darken-4 zero-margin">
      <div className="container padding-top-30">
        <div className="row">
          <div className="col s12 l4">
            <ReactPlayer
              width="300px"
              height="220px"
              controls
              url="https://www.youtube.com/watch?v=jD6EUgmFd9Y&t=3s"
            ></ReactPlayer>
          </div>
          <div className="col s12 l4">
            <ReactPlayer
              width="300px"
              height="220px"
              controls
              url="https://www.youtube.com/watch?v=AIXH5X8APtE&t=3s"
            ></ReactPlayer>
          </div>
          <div className="col s12 l4">
            <ReactPlayer
              width="300px"
              height="220px"
              controls
              url="https://www.youtube.com/watch?v=u00oY1tSlok&t=5s"
            ></ReactPlayer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Videos;
