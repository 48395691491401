import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  enrollSession,
  updateSessionStatus,
} from "../../store/actions/sessionActions";
import SessionImage from "./SessionImage";
import ModalYesNo from "./ModalYesNo";
import SessionContent from "./SessionContent";
import SessionFooter from "./SessionFooter";

//These imports are required to work with redux and firestore
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import StudentTypeImages from "./StudentTypeImages";

const SessionDetails = (props) => {
  const { auth } = props;
  if (!auth.uid) return <Redirect to="/signin" />;

  const handleEnrollSession = (e) => {
    e.preventDefault();
    props.enrollSession(props.sessionId, props.session);
    props.history.push("/student");
  };

  const handleCancelSession = (e) => {
    e.preventDefault();
    props.updateSessionStatus(props.sessionId, "Cancelled");
    props.history.push("/teacher");
  };

  const handleStartSession = (e) => {
    e.preventDefault();
    if (session.status === "LIVE")
      props.updateSessionStatus(props.sessionId, "Completed");
    else props.updateSessionStatus(props.sessionId, "LIVE");
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (auth.uid != session.teacherId) props.history.push("/sessions");
    else props.history.push("/teacher");
  };

  const { session } = props;

  const getMeetingLink = () => {
    if (auth.uid === session.teacherId) {
      return (
        <div className="input-field">
          <i className="small material-icons left">link</i>

          <a href={session.meetingLink}>{session.meetingLink}</a>
        </div>
      );
    }
  };

  const getEnrolledParticipants = () => {
    if (auth.uid === session.teacherId) {
      return (
        <div className="input-field">
          <i className="small material-icons left">people</i>
          {"Enrolled Participants: " + session.enrollmentCount}
        </div>
      );
    }
  };

  const getStartSessionButton = () => {
    if (auth.uid === session.teacherId) {
      if (session.status === "Upcoming" || session.status === "LIVE") {
        console.log(session.sessionDateTime.toDate() < new Date());
        return (
          <div className="input-field">
            <button
              className="waves-effect waves-light btn teal lighten-1 width100"
              onClick={handleStartSession}
              disabled={!(session.sessionDateTime.toDate() < new Date())}
            >
              {session.status === "LIVE" ? "End Session" : "Start Session"}
            </button>
          </div>
        );
      }
    }
  };

  const getActionButton = () => {
    if (auth.uid === session.teacherId) {
      if (
        session.status === "Cancelled" ||
        session.status === "LIVE" ||
        session.status === "Completed"
      ) {
        return (
          <a className="waves-effect waves-light btn red width100 lighten-1 disabled">
            {session.status}
          </a>
        );
      } else {
        return (
          <a
            className="waves-effect waves-light btn red width100 lighten-1 modal-trigger"
            // onClick={handleCancelSession}
            href="#modal1"
          >
            Cancel Session
          </a>
        );
      }
    } else {
      if (session.enrollmentCount >= 25) {
        return (
          <button className="waves-effect waves-light btn blue width100 lighten-1 disabled">
            0 Spot
          </button>
        );
      } else {
        return (
          <button
            className="waves-effect waves-light btn teal lighten-1 width100 "
            onClick={handleEnrollSession}
          >
            Enroll
          </button>
        );
      }
    }
  };

  const getThumbsUpDownCount = () => {
    if (auth.uid === session.teacherId) {
      return (
        <div>
          <i className="material-icons small left thumb-selected">thumb_up</i>
          <span className="grey-text left">
            {session.thumbsUpCount ? session.thumbsUpCount : 0}
          </span>
          <i className="material-icons small left thumb-selected">thumb_down</i>
          <span className="grey-text right">
            {session.thumbsDownCount ? session.thumbsDownCount : 0}
          </span>
        </div>
      );
    }
  };

  const ShowConfirmModal = () => {
    const title = "Confirmation";
    const message = `Currently there are ${session.enrollmentCount} student(s) have
    enrolled for this session. Are you sure that you want to cancel this session?`;

    return (
      <ModalYesNo
        title={title}
        message={message}
        handleConfirm={handleCancelSession}
      />
    );
  };

  if (session) {
    return (
      <div className="container section project-details">
        {ShowConfirmModal()}
        <div className="row">
          <div className="col s12 l12">
            <div className="card z-depth-3">
              <div className="card-image">
                <SessionImage id="sessionImage" subject={session.subject} />
                <span className="card-title session-title-detail width-85">
                  {session.title}
                </span>
              </div>

              <div className="card z-depth-0">
                <div className="card-content grey-text text-darken-3">
                  <SessionContent content={session.content} />
                  <div className="row">
                    <div className="col s12 l6">{getMeetingLink()}</div>
                    <div className="col s12 l3">
                      {getEnrolledParticipants()}
                    </div>
                    <div className="col s12 l3">{getStartSessionButton()}</div>
                  </div>
                </div>
                <div className="card-action gret lighten-4 grey-text">
                  <SessionFooter
                    teacherFirstName={session.teacherFirstName}
                    teacherLastName={session.teacherLastName}
                    sessionDateTime={session.sessionDateTime}
                    sessionDuration={session.duration}
                  />

                  <div className="row">
                    <StudentTypeImages studentTypes={session.studentTypes} />

                    <div className="col s12 m4 offset-m2 l3">
                      <div className="input-field">{getActionButton()}</div>
                    </div>
                    <div className="col s12 m4 l3">
                      <div className="input-field">
                        <button
                          className="waves-effect waves-light btn blue-grey darken-1 width100"
                          onClick={handleCancel}
                        >
                          {"< Back"}
                        </button>
                      </div>
                    </div>
                    <div className="col s12 m4 l3">
                      <div className="thumbs1">
                        <div className="input-field right">
                          {getThumbsUpDownCount()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container center">
        <p>Loading details...</p>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const sessions = state.firestore.data.sessions;
  const session = sessions ? sessions[id] : null;

  return {
    session: session,
    auth: state.firebase.auth,
    sessionId: id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    enrollSession: (sessionId, session) =>
      dispatch(enrollSession(sessionId, session)),
    updateSessionStatus: (sessionId, sessionStatus) =>
      dispatch(updateSessionStatus(sessionId, sessionStatus)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "sessions" }])
)(SessionDetails);
