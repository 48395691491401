import React from "react";
import SessionSummary from "./SessionSummary";
import { Link } from "react-router-dom";

const SessionList = ({ sessions }) => {
  if (sessions) {
    return (
      <div className="project-list section">
        <div className="row">
          {sessions &&
            sessions.map((session) => {
              return (
                <Link to={"/session/" + session.id} key={session.id}>
                  <SessionSummary session={session} />
                </Link>
              );
            })}
        </div>
      </div>
    );
  } else {
    return (
      <div className="container center">
        <p>Loading details...</p>
      </div>
    );
  }
};

export default SessionList;
