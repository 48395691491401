import React from "react";
import { NavLink } from "react-router-dom";

const SingnedOutLinks = () => {
  return (
    <ul className="right hide-on-med-and-down">
      <li>
        <NavLink to="/signin">Log in</NavLink>
      </li>
      <li>
        <NavLink
          className="waves-effect waves-light btn teal lighten-1"
          to="/signup"
        >
          Get Started
        </NavLink>
      </li>
    </ul>
  );
};

export default SingnedOutLinks;
