import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../store/actions/authActions";

class SingnedInLinksMobile extends Component {
  componentDidMount() {
    const M = window.M;

    var elem = document.querySelector(".sidenav");
    var instance = M.Sidenav.init(elem, {
      edge: "left",
      inDuration: 250,
    });
  }

  render() {
    return (
      <ul className="sidenav blue-grey darken-4" id="mobile-links">
        <li>
          <NavLink
            className="btn waves-effect waves-light btn-small teal darken-1 sidenav-close"
            to="/student"
          >
            Student
          </NavLink>
        </li>
        <li>
          <NavLink
            className="btn waves-effect waves-light btn-small teal darken-1 sidenav-close"
            to="/teacher"
          >
            Teacher
          </NavLink>
        </li>
        <li>
          <a
            className="btn waves-effect waves-light btn-small grey lighten-1 sidenav-close"
            onClick={this.props.signOut}
          >
            Log out
          </a>
        </li>
      </ul>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(null, mapDispatchToProps)(SingnedInLinksMobile);

// export default SingnedInLinksMobile;
