const Subjects = [
  {
    name: "Art & Craft",
    image: "/img/subjects/crafts.jpg",
    thumbnail: "/img/subjects/crafts.png",
  },
  {
    name: "Painting",
    image: "/img/subjects/painting.jpg",
    thumbnail: "/img/subjects/painting.png",
  },
  {
    name: "Guitar",
    image: "/img/subjects/guitar.jpg",
    thumbnail: "/img/subjects/guitar.png",
  },
  {
    name: "Music",
    image: "/img/subjects/music.jpg",
    thumbnail: "/img/subjects/music.png",
  },
  {
    name: "Singing",
    image: "/img/subjects/singing.jpg",
    thumbnail: "/img/subjects/singing.png",
  },
  {
    name: "Dance",
    image: "/img/subjects/dance.jpg",
    thumbnail: "/img/subjects/dance.png",
  },
  {
    name: "Cinema",
    image: "/img/subjects/cinema.jpg",
    thumbnail: "/img/subjects/cinema.png",
  },
  {
    name: "Cooking",
    image: "/img/subjects/cooking.jpg",
    thumbnail: "/img/subjects/cooking.png",
  },
  {
    name: "Fitness",
    image: "/img/subjects/gym.jpg",
    thumbnail: "/img/subjects/gym.png",
  },
  {
    name: "Yoga",
    image: "/img/subjects/yoga.jpg",
    thumbnail: "/img/subjects/yoga.png",
  },
  {
    name: "Decoration",
    image: "/img/subjects/decoration.jpg",
    thumbnail: "/img/subjects/decoration.png",
  },
  {
    name: "Coding",
    image: "/img/subjects/coding.jpg",
    thumbnail: "/img/subjects/coding.png",
  },
  {
    name: "Computer Science",
    image: "/img/subjects/computer.jpg",
    thumbnail: "/img/subjects/computer.png",
  },
  {
    name: "Math",
    image: "/img/subjects/math.jpg",
    thumbnail: "/img/subjects/math.png",
  },
  {
    name: "Geometry",
    image: "/img/subjects/geometry.jpg",
    thumbnail: "/img/subjects/geometry.png",
  },
  {
    name: "Physics",
    image: "/img/subjects/physics.jpg",
    thumbnail: "/img/subjects/physics.webp",
  },
  {
    name: "Biology",
    image: "/img/subjects/biology.jpg",
    thumbnail: "/img/subjects/biology.png",
  },
  {
    name: "Chemistry",
    image: "/img/subjects/chemistry.jpg",
    thumbnail: "/img/subjects/chemistry.png",
  },
  {
    name: "Economics",
    image: "/img/subjects/economic.jpg",
    thumbnail: "/img/subjects/economic.png",
  },
  {
    name: "English",
    image: "/img/subjects/english.jpg",
    thumbnail: "/img/subjects/english.png",
  },
  {
    name: "Languages",
    image: "/img/subjects/languages.jpg",
    thumbnail: "/img/subjects/languages.webp",
  },
  {
    name: "Geography",
    image: "/img/subjects/geography.jpg",
    thumbnail: "/img/subjects/geography.png",
  },
  {
    name: "History",
    image: "/img/subjects/history.jpg",
    thumbnail: "/img/subjects/history.png",
  },
  {
    name: "Psychology",
    image: "/img/subjects/psychology.jpg",
    thumbnail: "/img/subjects/psychology.png",
  },
  {
    name: "Motivation",
    image: "/img/subjects/motivation.jpg",
    thumbnail: "/img/subjects/motivation.png",
  },
  {
    name: "Writing",
    image: "/img/subjects/writing.jpg",
    thumbnail: "/img/subjects/writing.png",
  },
  {
    name: "Karate",
    image: "/img/subjects/karate.jpg",
    thumbnail: "/img/subjects/karate.png",
  },
  {
    name: "Stock Market",
    image: "/img/subjects/stockmarket.jpg",
    thumbnail: "/img/subjects/stockmarket.png",
  },
  {
    name: "Other",
    image: "/img/subjects/other.jpg",
    thumbnail: "/img/subjects/other.png",
  },
];

export default Subjects;
