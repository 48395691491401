import React, { useState } from "react";
import { connect } from "react-redux";
import { forgotPassword } from "../../store/actions/authActions";
import { Redirect } from "react-router-dom";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    props.forgotPassword(email);
    setEmail("");
  };

  const { authError, auth } = props;
  if (auth.uid) return <Redirect to="/student" />;

  return (
    <div className="row">
      <div className="col s12 l5 offset-l3">
        <div className="card">
          <div className="container">
            <form onSubmit={handleSubmit} className="white" autoComplete="off">
              <h5 className="teal-text text-darken-1 center">
                Forgot Password
              </h5>
              <div className="input-field">
                {/* <i className="material-icons prefix small">email</i> */}
                <label htmlFor="email">Type your email</label>
                <input
                  type="email"
                  id="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                />
              </div>
              <div className="input-field center">
                <div className="row">
                  <div className="col s12 l6 offset-l3">
                    <button
                      className="waves-effect waves-light btn teal darken-1 width100"
                      disabled={!email}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div className="red-text center">{authError}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const err = state.auth.authError;
  state.auth.authError = null;
  return {
    authError: err,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (email) => dispatch(forgotPassword(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
