import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  cancelEnrolledSession,
  updateThumbsUp,
  updateThumbsDown,
} from "../../store/actions/sessionActions";
import SessionImage from "../sessions/SessionImage";
import SessionContent from "../sessions/SessionContent";
import SessionFooter from "../sessions/SessionFooter";

//These imports are required to work with redux and firestore
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import StudentTypeImages from "../sessions/StudentTypeImages";

class EnrolledSessionDetails extends Component {
  render() {
    const { enrolledId, auth, enrolledSession, session } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;

    const handleCancelEnrolledSession = (e) => {
      e.preventDefault();
      this.props.cancelEnrolledSession(enrolledId, enrolledSession.sessionId);
      this.props.history.push("/student");
    };

    const handleCancel = (e) => {
      e.preventDefault();
      this.props.history.push("/student");
    };

    const handleThumbsUp = (e) => {
      e.preventDefault();

      if (session.sessionDateTime.toDate() < new Date()) {
        if (!(enrolledSession.thumbsUp || enrolledSession.thumbsDown)) {
          const thumbsUp = document.querySelector(".thumbsUp");
          thumbsUp.classList.add("thumb-selected");
          this.props.updateThumbsUp(enrolledId, enrolledSession.sessionId);
        }
      }
    };

    const handleThumbsDown = (e) => {
      e.preventDefault();

      if (session.sessionDateTime.toDate() < new Date()) {
        if (!(enrolledSession.thumbsUp || enrolledSession.thumbsDown)) {
          const thumbsDown = document.querySelector(".thumbsDown");
          thumbsDown.classList.add("thumb-selected");
          this.props.updateThumbsDown(enrolledId, enrolledSession.sessionId);
        }
      }
    };

    const getJoinSessionButton = () => {
      let buttonStyle = "waves-effect waves-light btn teal lighten-1 width100";

      if (session.status === "Completed" || session.status === "Cancelled") {
        buttonStyle += " disabled";
      }

      return (
        <div className="input-field">
          <a
            className={buttonStyle}
            target="_blank"
            href={session.meetingLink}
            rel="noreferrer"
            disabled={!(session.sessionDateTime.toDate() < new Date())}
          >
            Join Session
          </a>
        </div>
      );
    };

    const getThumbUpSelectedStyle = () => {
      if (enrolledSession && enrolledSession.thumbsUp === 1) {
        return "thumbsUp material-icons small left thumb-selected";
      } else {
        return "thumbsUp material-icons small left";
      }
    };

    const getThumbDownSelectedStyle = () => {
      if (enrolledSession && enrolledSession.thumbsDown === 1) {
        return "thumbsDown material-icons small left thumb-selected";
      } else {
        return "thumbsDown material-icons small left";
      }
    };

    const getThumbsEnabled = () => {
      if (session && session.status === "Completed") {
        if (
          enrolledSession &&
          enrolledSession.thumbsDown !== 1 &&
          enrolledSession.thumbsUp !== 1
        ) {
          return "thumbs";
        }
      }
    };

    if (session) {
      return (
        <div className="container section project-details">
          <div className="row">
            <div className="col s12 l12">
              <div className="card z-depth-3">
                <div className="card-image">
                  <SessionImage id="sessionImage" subject={session.subject} />
                  <span className="card-title session-title-detail width-85">
                    {session.title}
                  </span>
                </div>

                <div className="card z-depth-0">
                  <div className="card-content grey-text text-darken-3">
                    <SessionContent content={session.content} />
                    <div className="row">
                      <div className="col s12 m7 l9">
                        Note: click{" "}
                        <span className="text-bold">JOIN SESSION</span> button
                        to join the session at the scheduled date and time.
                      </div>
                      <div className="col s12 m5 l3">
                        {getJoinSessionButton()}
                      </div>
                    </div>
                  </div>
                  <div className="card-action gret lighten-4 grey-text">
                    <SessionFooter
                      teacherFirstName={session.teacherFirstName}
                      teacherLastName={session.teacherLastName}
                      sessionDateTime={session.sessionDateTime}
                      sessionDuration={session.duration}
                    />
                    <div className="row">
                      <StudentTypeImages studentTypes={session.studentTypes} />
                      <div className="col s12 m4 offset-m2 l3">
                        <div className="input-field">
                          <button
                            className="waves-effect waves-light btn red width100 lighten-1"
                            onClick={handleCancelEnrolledSession}
                          >
                            Unenroll
                          </button>
                        </div>
                      </div>
                      <div className="col s12 m4 l3">
                        <div className="input-field">
                          <button
                            className="waves-effect waves-light btn blue-grey darken-1 width100"
                            onClick={handleCancel}
                          >
                            {"< Back"}
                          </button>
                        </div>
                      </div>
                      <div className="col s12 m4 l3">
                        <div className={getThumbsEnabled()}>
                          <div className="input-field right">
                            <i
                              className={getThumbUpSelectedStyle()}
                              onClick={handleThumbsUp}
                            >
                              thumb_up
                            </i>
                            <i
                              className={getThumbDownSelectedStyle()}
                              onClick={handleThumbsDown}
                            >
                              thumb_down
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container center">
          <p>Loading details...</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const enrolledId = ownProps.match.params.id;
  //const enrollments = state.firestore.data.enrolledSessions;
  // const enrolledSession = enrollments ? enrollments[enrolledId] : null;
  const enrolledSession = state.firestore.data.enrolledSession;

  const sessions = state.firestore.data.sessions;
  const session =
    sessions && enrolledSession ? sessions[enrolledSession.sessionId] : null;

  return {
    enrolledSession: enrolledSession,
    session: session,
    auth: state.firebase.auth,
    enrolledId: enrolledId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelEnrolledSession: (enrolledId, sessionId) =>
      dispatch(cancelEnrolledSession(enrolledId, sessionId)),
    updateThumbsUp: (enrolledId, sessionId) =>
      dispatch(updateThumbsUp(enrolledId, sessionId)),
    updateThumbsDown: (enrolledId, sessionId) =>
      dispatch(updateThumbsDown(enrolledId, sessionId)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.auth.uid) return [];

    return [
      {
        collection: "enrollments",
        doc: props.enrolledId,
        storeAs: "enrolledSession",
      },
      {
        collection: "sessions",
        //doc: props.enrolledSession.sessionId,
        where: [["teacherId", "!=", props.auth.uid]],
        storeAs: "sessions",
      },
    ];
  })
)(EnrolledSessionDetails);
