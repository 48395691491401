import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SingnedInLinks from "./SignedInLinks";
import SingnedOutLinks from "./SignedOutLinks";
import SingnedInLinksMobile from "./SignedInLinksMobile";
import SingnedOutLinksMobile from "./SignedOutLinksMobile";

const Navbar = (props) => {
  const { auth, profile } = props;
  return (
    <>
      <div className="navbar-fixed">
        <nav className="nav-wrapper blue-grey darken-4">
          <div className="container">
            <Link to="/" className="brand-logo">
              {/* <img src={process.env.PUBLIC_URL + "/img/freedemy_logo.png"} /> */}
              <span className="grey-text text-lighten-5">free</span>
              <span className="teal-text text-accent-3">demy</span>
            </Link>

            <a href="#" className="sidenav-trigger" data-target="mobile-links">
              <i className="material-icons">menu</i>
            </a>
            {auth.uid ? (
              <SingnedInLinks profile={profile} />
            ) : (
              <SingnedOutLinks />
            )}
          </div>
        </nav>
      </div>

      {auth.uid ? (
        <SingnedInLinksMobile profile={profile} />
      ) : (
        <SingnedOutLinksMobile />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(Navbar);
