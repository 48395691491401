import React, { Component } from "react";
import SessionList from "../sessions/SessionList";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import StudentTypes from "../sessions/StudentTypes";

class SessionsAvailable extends Component {
  state = {
    searchValue: "",
    studentTypes: "",
    excludeZeroSpot: false,
  };

  componentDidMount() {
    const M = window.M;

    var elem = document.querySelectorAll("select");
    var instance = M.FormSelect.init(elem, null);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleCheckbox = (e) => {
    // console.log(e.target.checked);
    this.setState({
      excludeZeroSpot: e.target.checked,
    });
  };

  render() {
    const { upcomingSessions, enrolledSessions, auth } = this.props;

    if (!auth.uid) return <Redirect to="/signin" />;

    const getUpcomingSessions = () => {
      let availableSessions = [];

      if (upcomingSessions && enrolledSessions) {
        //To exclude enrolled sessions from upcoming sessions
        availableSessions = upcomingSessions.filter(
          (x) => !enrolledSessions.find((y) => y.sessionId === x.id)
        );

        //To filter out sessions with entered subject
        availableSessions = availableSessions.filter(
          (x) =>
            x.subject
              .toLowerCase()
              .indexOf(this.state.searchValue.toLowerCase()) >= 0
        );

        //To sort sessions list by session date-time
        const availableSessionsSorted = availableSessions
          .filter((x) => x.sessionDateTime.toDate() >= new Date())
          .sort((a, b) => {
            return a.sessionDateTime.toDate() - b.sessionDateTime.toDate();
          });

        let availableSessionsToDisplay = availableSessionsSorted;
        //const excludeZeroSpot = document.querySelector("#excludeZeroSpot");

        //To filter out zero spot left sessions
        if (this.state.excludeZeroSpot) {
          availableSessionsToDisplay = availableSessionsSorted.filter(
            (x) => x.enrollmentCount < 25
          );
        }

        //Filter by student types
        if (this.state.studentTypes) {
          console.log(this.state.studentTypes);
          availableSessionsToDisplay = availableSessionsToDisplay.filter(
            (x) =>
              x.studentTypes &&
              x.studentTypes.indexOf(this.state.studentTypes) >= 0
          );
        }

        return (
          <div className="col s12 l12">
            <SessionList sessions={availableSessionsToDisplay.slice(0, 50)} />
          </div>
        );
      } else {
        return <div className="grey-text">Not found</div>;
      }
    };

    return (
      <div className="container">
        <div className="row">
          <div className="col s12 m6 l4">
            <div className="input-field">
              <label htmlFor="searchValue">Type subject to search</label>
              <input
                type="text"
                id="searchValue"
                autoComplete="off"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="col s12 m6 l4">
            <div className="input-field">
              <select
                id="studentTypes"
                // className="browser-default"
                //value={this.state.value}
                onChange={this.handleChange}
                defaultValue=""
              >
                <option value="">All</option>
                {StudentTypes &&
                  StudentTypes.map((student) => {
                    return (
                      <option
                        value={student.value}
                        data-icon={process.env.PUBLIC_URL + student.thumbnail}
                      >
                        {student.name}
                      </option>
                    );
                  })}
              </select>
              <label>Sessions suitable for</label>
            </div>
          </div>
          <div className="col s12 m6 l4">
            <div className="input-field">
              <label>
                <input
                  id="excludeZeroSpot"
                  type="checkbox"
                  className="filled-in"
                  onChange={this.handleCheckbox}
                />
                <span>Exclude zero spot left</span>
              </label>
            </div>
          </div>
        </div>

        <div className="row">{getUpcomingSessions()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    upcomingSessions: state.firestore.ordered.upcomingSessions,
    enrolledSessions: state.firestore.ordered.enrolledSessions,
    auth: state.firebase.auth,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (!props.auth.uid) return [];

    return [
      {
        collection: "sessions",
        where: [
          ["teacherId", "!=", props.auth.uid],
          ["status", "==", "Upcoming"],
        ],
        storeAs: "upcomingSessions",
      },
      {
        collection: "enrollments",
        where: ["userId", "==", props.auth.uid],
        orderBy: ["sessionDateTime"],
        storeAs: "enrolledSessions",
      },
    ];
  })
)(SessionsAvailable);
