import React from "react";
import StudentTypes from "./StudentTypes";

const StudentTypeImages = (props) => {
  let images = [];
  if (props.studentTypes) {
    const types = props.studentTypes.split(",");

    types.map((st) => {
      const stObj = StudentTypes.filter((x) => x.value === st);
      images.push(
        <img
          src={process.env.PUBLIC_URL + stObj[0].thumbnail}
          alt={stObj[0].name}
          className="student-type"
        />
      );
    });
  }
  return (
    <div className="col s12 m4 l3 student-types">
      <div className="input-field">{images}</div>
    </div>
  );
};

export default StudentTypeImages;
