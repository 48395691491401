import React from "react";

const SessionStatus = (props) => {
  let badgeClass = "";

  if (props.status === "Upcoming") {
    badgeClass = "new badge green";
  } else if (props.status === "Completed") {
    badgeClass = "new badge blue";
  } else if (props.status === "LIVE") {
    badgeClass = "new badge orange";
  } else {
    badgeClass = "new badge grey";
  }

  return (
    <span className={badgeClass} data-badge-caption="">
      {props.status}
    </span>
  );
};

export default SessionStatus;
