import React from "react";
import SessionImage from "./SessionImage";
import moment from "moment";
import SpotsLeft from "./SpotsLeft";
import SessionStatus from "./SessionStatus";
import SessionHeader from "./SessionHeader";

const SessionSummary = ({ session }) => {
  const getSessionDateTime = () => {
    if (session.sessionDateTime) {
      return moment(session.sessionDateTime.toDate()).format("llll");
    } else {
      return `${session.sessionDate} ${session.sessionTime} ${session.timeZone}`;
    }
  };

  return (
    <div className="col s12 m6 l4">
      <div className="card z-depth-3" id={session.key}>
        <div className="card-image">
          {/* <SessionHeader subject={session.subject} /> */}
          <SessionImage subject={session.subject} />
          <span className="card-title session-title-summary width-85">
            {session.title}
          </span>
        </div>
        <div className="card-content grey-text text-darken-3">
          <SessionStatus status={session.status} />
          <h6 className="">{session.subject}</h6>
          {/* <span>{session.studentTypes}</span> */}
          <p className="grey-text">{getSessionDateTime()}</p>
          <SpotsLeft enrollmentCount={session.enrollmentCount} />
        </div>
      </div>
    </div>
  );
};

export default SessionSummary;
