import React, { Component } from "react";
// import M from "materialize-css/dist/js/materialize.min.js";
import { NavLink } from "react-router-dom";

class SingnedOutLinksMobile extends Component {
  componentDidMount() {
    const M = window.M;

    var elem = document.querySelector(".sidenav");
    var instance = M.Sidenav.init(elem, {
      edge: "left",
      inDuration: 250,
    });
  }

  render() {
    return (
      <ul className="sidenav blue-grey darken-4" id="mobile-links">
        <li>
          <NavLink
            className="btn waves-effect waves-light btn-small teal darken-1 sidenav-close"
            to="/signin"
          >
            {/* <i className="material-icons left">email</i> */}
            Log in
          </NavLink>
        </li>
        <li>
          <NavLink
            className="btn waves-effect waves-light btn-small teal darken-1 sidenav-close"
            to="/signup"
          >
            Get Started
          </NavLink>
        </li>
      </ul>
    );
  }
}

export default SingnedOutLinksMobile;
