import React from "react";
import { NavLink } from "react-router-dom";

const HomeSection = (props) => {
  const titleColor = props.color + "-text text-darken-3 z-depth-0";
  const buttonColor =
    props.color + " darken-3 waves-effect waves-light btn width100";

  let colImage = "col s12 l6";
  let colText = "col s12 l6";
  if (props.align === "right") {
    colImage += " push-l6";
    colText += " pull-l6";
  }

  return (
    <div className="home-section">
      <div className="row">
        <div className={colImage}>
          <img src={props.image} alt={props.color} className="responsive-img" />
        </div>
        <div className={colText}>
          <h2 className={titleColor}>{props.title}</h2>
          <h3 className="grey-text text-darken-4">{props.header}</h3>
          <p>{props.desc}</p>
          <div className="row">
            <div className="col s12 l5">
              <div className="input-field">
                <NavLink to="/student" className={buttonColor}>
                  Get Started
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection;
