const StudentTypes = [
  {
    name: "School Students",
    value: "SS",
    thumbnail: "/img/subjects/school-students.png",
  },
  {
    name: "College Students",
    value: "CS",
    thumbnail: "/img/subjects/college-students.png",
  },
  {
    name: "Job Seekers",
    value: "JS",
    thumbnail: "/img/students/job.png",
  },
  {
    name: "Homemakers",
    value: "HM",
    thumbnail: "/img/subjects/woman.png",
  },
  {
    name: "Working Professionals",
    value: "WP",
    thumbnail: "/img/subjects/suitcase.png",
  },
  {
    name: "Retired Persons",
    value: "RP",
    thumbnail: "/img/subjects/retirement.png",
  },
];

export default StudentTypes;
