import React from "react";

const SpotsLeft = (props) => {
  if (props.enrollmentCount >= 0) {
    const spotsLeft = 25 - props.enrollmentCount;

    if (spotsLeft > 20) {
      return <p className="blue-text">20+ spots left</p>;
    } else if (spotsLeft > 15) {
      return <p className="blue-text">15+ spots left</p>;
    } else if (spotsLeft > 10) {
      return <p className="orange-text">10+ spots left</p>;
    } else if (spotsLeft > 5) {
      return <p className="orange-text">5+ spots left</p>;
    } else if (spotsLeft > 1) {
      return <p className="red-text">{spotsLeft} spots left</p>;
    } else if (spotsLeft === 1) {
      return <p className="red-text">Just 1 spot left</p>;
    } else {
      return <p className="red-text">zero spot left</p>;
    }
  } else {
    return <></>;
  }
};

export default SpotsLeft;
