import React from "react";
import moment from "moment";

const SessionFooter = (props) => {
  return (
    <div className="row">
      <div className="col s12 l4">
        <i className="small material-icons left">person</i>
        Scheduled by: {props.teacherFirstName} {props.teacherLastName}
      </div>
      <div className="col s12 l4">
        <i className="small material-icons left">access_time</i>
        {moment(props.sessionDateTime.toDate()).format("llll")}
      </div>
      <div className="col s12 l4">
        <i className="small material-icons left">timelapse</i>
        <span>Duration (minutes): {props.sessionDuration}</span>
      </div>
    </div>
  );
};

export default SessionFooter;
