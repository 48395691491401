import React from "react";
import SessionList from "../sessions/SessionList";
import { NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

const TeacherDashboard = (props) => {
  const { scheduledSessions, auth } = props;
  if (!auth.uid) return <Redirect to="/signin" />;

  const getScheduledSessions = () => {
    if (scheduledSessions && scheduledSessions.length === 0) {
      return (
        <div className="grey-text">
          {"You haven't scheduled any session yet!"}
          <NavLink to="/sessions/create">click here</NavLink> to schedule a
          session
        </div>
      );
    } else {
      const schedulesFuture =
        scheduledSessions &&
        scheduledSessions.filter(
          (x) => x.sessionDateTime.toDate() >= new Date()
        );

      const schedulesPast =
        scheduledSessions &&
        scheduledSessions
          .filter((x) => x.sessionDateTime.toDate() < new Date())
          .sort((a, b) => {
            return b.sessionDateTime.toDate() - a.sessionDateTime.toDate();
          });
      return (
        <div className="col s12 l12">
          <h6>Upcoming:</h6>
          {schedulesFuture && schedulesFuture.length > 0 ? (
            <SessionList sessions={schedulesFuture} />
          ) : (
            <span className="grey-text">{"- Nothing"}</span>
          )}

          <h6>Completed:</h6>
          {schedulesPast && schedulesPast.length > 0 ? (
            <SessionList sessions={schedulesPast} />
          ) : (
            <span className="grey-text">{"- Nothing"}</span>
          )}
        </div>
      );
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col s12 l12">
          <blockquote className="flow-text">
            As a <span className="text-bold">Teacher</span>, you can schedule
            sessions to share your knowledge on anything
          </blockquote>

          <ul>
            <li></li>
            <li className="margin-top-10">
              <i className="material-icons small left">chevron_right</i>
              click <span className="text-bold">SCHEDULE A SESSION</span> button
            </li>
            <li className="margin-top-10">
              <i className="material-icons small left">chevron_right</i>
              fill the form with required details about your session and click{" "}
              <span className="text-bold">CREATE</span> button
            </li>
            <li className="margin-top-10">
              <i className="material-icons small left">chevron_right</i>
              once it is scheduled, you will see it under{" "}
              <span className="text-bold">Your Scheduled Sessions</span> section
            </li>
            <li className="margin-top-10">
              <i className="material-icons small left">chevron_right</i>
              <span className="text-bold">conduct</span> the session at the
              scheduled date and time
            </li>
            <li className="margin-top-10">
              <i className="material-icons small left">chevron_right</i>
              {"do you need help? "}
              <a
                href="https://youtu.be/AIXH5X8APtE"
                target="_blank"
                rel="noreferrer"
              >
                watch this video
              </a>
            </li>
          </ul>
          <div className="row">
            <div className="col s12 m6 offset-m3 l4 offset-l4">
              <div className="input-field">
                <NavLink
                  to="/sessions/create"
                  className="btn waves-effect waves-light teal lighten-1 width100"
                >
                  {/* <i className="material-icons small left">create</i> */}
                  Schedule a Session
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <h5>Your Scheduled Sessions</h5>
        <hr />
        {getScheduledSessions()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    scheduledSessions: state.firestore.ordered.scheduledSessions,
    auth: state.firebase.auth,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (!props.auth.uid) return [];
    return [
      {
        collection: "sessions",
        where: ["teacherId", "==", props.auth.uid],
        orderBy: ["sessionDateTime"],
        storeAs: "scheduledSessions",
      },
    ];
  })
)(TeacherDashboard);
