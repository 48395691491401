export const createSession = (session, studentTypes) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    //make async call to DB
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authId = getState().firebase.auth.uid;

    session.content = session.content.replace(/\n/g, "<br/>");

    if (!session.meetingLink.startsWith("https"))
      session.meetingLink = "https://" + session.meetingLink;

    firestore
      .collection("sessions")
      .add({
        ...session,
        studentTypes: studentTypes,
        teacherFirstName: profile.firstName,
        teacherLastName: profile.lastName,
        teacherId: authId,
        status: "Upcoming",
        enrollmentCount: 0,
        timestamp: new Date(),
        sessionDateTime: new Date(
          `${session.sessionDate} ${session.sessionTime}`
        ),
      })
      .then(() => {
        dispatch({ type: "CREATE_SESSION", session });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_SESSION_ERROR", err });
      });
  };
};

export const enrollSession = (sessionId, session) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;

    firestore
      .collection("enrollments")
      .add({
        userId: userId,
        sessionId: sessionId,
        subject: session.subject,
        title: session.title,
        // content: session.content,
        // sessionDate: session.sessionDate,
        // sessionTime: session.sessionTime,
        sessionDateTime: session.sessionDateTime,
        status: session.status,
        // timeZone: session.timeZone,
        // meetingLink: session.meetingLink,
        timestamp: new Date(),
      })
      .then(() => {
        firestore
          .collection("sessions")
          .doc(sessionId)
          .update({
            enrollmentCount: session.enrollmentCount + 1,
          });
        dispatch({ type: "ENROLL_SESSION" });
      })
      .catch((err) => {
        dispatch({ type: "ENROLL_SESSION_ERROR", err });
      });
  };
};

export const updateSessionStatus = (sessionId, sessionStatus) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const ENROLLED_SESSIONS = firestore.collection("enrollments");

    firestore
      .collection("sessions")
      .doc(sessionId)
      .update({
        status: sessionStatus,
      })
      .then(() => {
        ENROLLED_SESSIONS.where("sessionId", "==", sessionId)
          .get()
          .then((snapshots) => {
            if (snapshots.size > 0) {
              snapshots.forEach((enroll) => {
                ENROLLED_SESSIONS.doc(enroll.id).update({
                  status: sessionStatus,
                });
              });
            }
          });
        dispatch({ type: "UPDATE_SESSION" });
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_SESSION_ERROR", err });
      });
  };
};

export const cancelEnrolledSession = (enrolledId, sessionId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("enrollments")
      .doc(enrolledId)
      .delete()
      .then(() => {
        firestore
          .collection("sessions")
          .doc(sessionId)
          .update({
            enrollmentCount: firestore.FieldValue.increment(-1),
          });

        dispatch({ type: "CANCEL_ENROLLED_SESSION" });
      })
      .catch((err) => {
        dispatch({ type: "CANCEL_ENROLLED_SESSION_ERROR", err });
      });
  };
};

export const updateThumbsUp = (enrolledId, sessionId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("enrollments")
      .doc(enrolledId)
      .update({
        thumbsUp: 1,
        thumbsDown: 0,
      })
      .then(() => {
        firestore
          .collection("sessions")
          .doc(sessionId)
          .update({
            thumbsUpCount: firestore.FieldValue.increment(1), //session.thumbsUpCount + 1,
          });

        dispatch({ type: "THUMBS_UP" });
      })
      .catch((err) => {
        dispatch({ type: "THUMBS_UP_ERROR", err });
      });
  };
};

export const updateThumbsDown = (enrolledId, sessionId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("enrollments")
      .doc(enrolledId)
      .update({
        thumbsUp: 0,
        thumbsDown: 1,
      })
      .then(() => {
        firestore
          .collection("sessions")
          .doc(sessionId)
          .update({
            thumbsDownCount: firestore.FieldValue.increment(1), //session.thumbsDownCount + 1,
          });

        dispatch({ type: "THUMBS_DOWN" });
      })
      .catch((err) => {
        dispatch({ type: "THUMBS_DOWN_ERROR", err });
      });
  };
};
