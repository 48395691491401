import React from "react";
import HomeSection from "./HomeSection";

const HomeSections = () => {
  return (
    <section className="container " id="section1">
      <HomeSection
        image="img/02.webp"
        title="EXCLUSIVE ACCESS"
        color="deep-orange"
        align="left"
        header="unlimited learning on freedemy for FREE"
        desc="Get access to thousands of teachers sharing their knowledge via LIVE online sessions
              through this platform for FREE."
      />
      <HomeSection
        image="img/03.webp"
        title="100% FREE"
        color="blue"
        align="right"
        header="learn anything from anywhere, anytime for FREE"
        desc="There are thousands of experienced passionate people sharing their
        knowledge and experience LIVE. Find the topics of your interest
        and enroll for any upcoming session."
      />
      <HomeSection
        image="img/04.webp"
        title="EASY REGISTRATION"
        color="green"
        align="left"
        header="super-fast and simple registration process"
        desc="Get everything set up and ready within a minute. Just register with your email id, name and you're ready to explore the
        world of learning anything from anywhere for FREE."
      />
    </section>
  );
};

export default HomeSections;
