import React from "react";
import { FaYoutube } from "react-icons/fa";
import { MdEmail, MdFeedback } from "react-icons/md";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="page-footer grey darken-3">
      <div className="container">
        <div className="row">
          <div className="col s12 l5">
            <span className="footer-header-text">About Us</span>
            <ul>
              <li className="margin-top-10">
                <span className="text-bold">freedemy</span> is a non-profit
                platform which is created to connect interested learners with
                passionate people who love to share their knowledge and
                experience for FREE of cost.
              </li>
              <li className="margin-top-10">
                As a{" "}
                <NavLink to="/student" className="teal-text text-accent-3">
                  Student
                </NavLink>
                , you can enroll for any upcoming sessions of your interest and
                learn from online sessions <i>LIVE</i>.
              </li>
              <li className="margin-top-10">
                As a{" "}
                <NavLink to="/teacher" className="teal-text text-accent-3">
                  Teacher
                </NavLink>
                , you can schedule any sessions of your favorite topic and share
                your knowledge with people from across the world.
              </li>
            </ul>
          </div>
          <div className="col s12 l5">
            <span className="footer-header-text">Help freedemy Grow</span>
            <ul>
              <li className="margin-top-10">
                If you believe that this platform is a good initiative and want
                to support the team then you can help us by donating or backing
                us on Patreon.
              </li>
              <li className="margin-top-10">
                Any amount would help support and continue development on this
                project and is greatly appreciated.
              </li>
              <li className="margin-top-10">
                <a
                  className="btn waves-effect waves-light red darken-3"
                  href="https://www.patreon.com/bePatron?u=54238130"
                >
                  Become a Patron!
                </a>
              </li>
            </ul>
          </div>
          <div className="col s12 l2 ">
            <span className="footer-header-text">Contact</span>
            <ul>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCkQAj9HNYi4qzuoZtZlKVzw"
                  target="_blank"
                  rel="noreferrer"
                  className="red-text text-darken-3 text-medium"
                >
                  <FaYoutube />
                </a>{" "}
                <a
                  href="https://forms.gle/FEKnycoxZAmPAsY36"
                  target="_blank"
                  rel="noreferrer"
                  className="text-medium"
                >
                  <MdFeedback />
                </a>{" "}
                <a
                  href="mailto:freedemy.online@gmail.com"
                  className="green-text text-medium"
                >
                  <MdEmail />
                </a>
              </li>
              <li></li>
              {/* <li>
                <a
                  href="mailto:freedemy.online@gmail.com"
                  className="grey-text text-lighten-3"
                >
                  freedemy.online@gmail.com
                </a>
              </li> */}

              {/*<li>
                <a href="#" className="grey-text text-lighten-3">
                  Linked In
                </a>{" "}
              </li>
              <li>
                <a href="#" className="grey-text text-lighten-3">
                  Instagram
                </a>{" "}
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright grey darken-4">
        <div className="container center-align">
          &copy; 2022 freedemy | last updated 2022.09.18
        </div>
      </div>
    </footer>
  );
};

export default Footer;
