import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import "./Metrics.css";

const Metrics = (props) => {
  const formatCount = (count) => {
    let formattedCount;
    if (count >= 1000 && count < 1000000) {
      formattedCount = count / 1000;
      return `${formattedCount.toFixed(1)}K+`;
    } else if (count > 1000000) {
      formattedCount = count / 1000000;
      return `${formattedCount.toFixed(1)}M+`;
    } else {
      return count;
    }
  };

  const counts = props.counts && props.counts["xnWa6ZjTBN6E1l50f1bq"];

  let usersCount = counts ? counts.userCount : 0;
  let sessionsCount = counts ? counts.sessionCount : 0;
  let enrollmentsCount = counts ? counts.enrollmentCount : 0;

  return (
    <>
      <div className="row blue-grey darken-4 zero-margin">
        <div className="container padding-top-30">
          <div className="row">
            <div className="col s12 l4 center">
              <div className="btn-floating btn-large teal lighten-1">
                <h4 className="text-middle">{formatCount(usersCount)}</h4>
              </div>
              <h5 className="teal-text text-lighten-2">Active Users</h5>
            </div>
            <div className="col s12 l4 center">
              <div className="btn-floating btn-large green lighten-1">
                <h4 className="text-middle">{formatCount(sessionsCount)}</h4>
              </div>
              <h5 className="green-text text-lighten-2">Scheduled Sessions</h5>
            </div>
            <div className="col s12 l4 center">
              <div className="btn-floating btn-large orange lighten-1">
                <h4 className="text-middle">{formatCount(enrollmentsCount)}</h4>
              </div>
              <h5 className="orange-text text-lighten-2">Total Enrollments</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    counts: state.firestore.data.counts,
    // users: state.firestore.ordered.users,
    // sessions: state.firestore.ordered.sessions,
    // enrollments: state.firestore.ordered.enrollments,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => {
    return [
      { collection: "counts" },
      // { collection: "users" },
      // { collection: "sessions" },
      // { collection: "enrollments" },
      // { collection: "subjects" },
    ];
  })
)(Metrics);
