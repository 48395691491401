import React, { Component } from "react";

const testimonials = [
  // {
  //   name: "Subbiah Chidambaram",
  //   image: "img/testimonial/subbu.png",
  //   color: "blue lighten-5",
  //   linkedin: "https://www.linkedin.com/in/subbiah-chidambaram-7a6752101/",
  //   comment:
  //     "Hats off to you Debabrata Das. Excellent thought process. This has a great potential to connect generous teachers and enthusiastic students. Overall great creativity.",
  // },
  {
    name: "Swati Chandrakar",
    image: "img/testimonial/swati.webp",
    color: "deep-purple lighten-5",
    linkedin: "https://www.linkedin.com/in/swati-chandrakar-669316186/",
    comment:
      "Want to learn anything? Want to teach anything? Enjoy sharing knowledge? Then the wait is over. In this fast pace world, let's try to bring a change by contributing our knowledge to people.",
  },
  {
    name: "Vamsidhar Parasarampuram",
    image: "img/testimonial/vamsi.webp",
    color: "teal lighten-5",
    linkedin: "https://www.linkedin.com/in/vamsidhar-p-198152b4/",
    comment:
      "If you know something rare, what is better than to share. Enough with the despair, as it is now time to glare at freedemy.host - a platform that unites aspiring learners and inspiring teachers. Give it a try.",
  },
  {
    name: "George Javaras",
    image: "img/testimonial/george.webp",
    color: "cyan lighten-5",
    linkedin: "https://www.linkedin.com/in/javarasgeorge/",
    comment:
      "A wonderful addition to the area of online learning/teaching. Whether you are a teacher or a student, this could be your place to hang out and collaborate.",
  },
  {
    name: "Jhalak Patidar",
    image: "img/testimonial/jhalak.webp",
    color: "purple lighten-5",
    linkedin: "https://www.youtube.com/channel/UC-WDGfrNQ1yycEfcOa5Cspg",
    comment:
      "In freedemy you can learn anything from the given topics for free. I think it is a very nice initiative as it will teach many people what they desired to learn for no fee. Also it inspires people who have the passion to teach.",
  },
  // {
  //   name: "Avinash Rastogi",
  //   image: "img/testimonial/avinash.png",
  //   color: "green lighten-5",
  //   linkedin: "https://www.linkedin.com/in/avinashrastogi/",
  //   comment:
  //     "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes.",
  // },
  // {
  //   name: "Hafizur-Rahman Malik",
  //   image: "img/testimonial/hafiz.png",
  //   color: "yellow lighten-5",
  //   linkedin: "https://www.linkedin.com/in/hafizur-rahman-malik-9a726333/",
  //   comment:
  //     "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes.",
  // },
];

class Testimonial extends Component {
  componentDidMount() {
    const M = window.M;

    var elem = document.querySelector(".carousel");
    var instance = M.Carousel.init(elem, {
      fullWidth: false,
      indicators: true,
      noWrap: false,
      padding: 0,
      numVisible: 5,
    });

    setInterval(function () {
      var instance = M.Carousel.getInstance(elem);
      instance.next();
    }, 6000);
  }

  render() {
    return (
      <div className="carousel carousel-slider center">
        {testimonials.map((testimonial) => {
          const style = `carousel-item black-text ${testimonial.color}`;

          return (
            <div className={style}>
              <div className="container">
                <img src={testimonial.image} alt={testimonial.name} />
                <div className="row">
                  <a
                    className="carousel-name-text indigo-text text-darken-4"
                    href={testimonial.linkedin}
                    target="_blank"
                    rel="noopener"
                  >
                    {testimonial.name}
                  </a>
                </div>
                <p className="carousel-item-text black-text">
                  {testimonial.comment}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Testimonial;
