import React from "react";
import "./Vision.css";
const Vision = () => {
  return (
    <>
      <div className="row teal darken-2 zero-margin white-text">
        <div className="row">
          <div className="container">
            <div className="col s12 l12">
              <h3>Anyone can Learn Anything from Anywhere for FREE</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="container">
            <div className="col s12 l10">
              <blockquote className="flow-text">
                There are thousands of people with expertise in different skills
                across the globe. On the other hand, there are millions of
                students who would be interested to learn those skills.
                <p>
                  <span className="text-bold">freedemy</span> which is derived
                  from the idea of "Free Academy" is a platform to connect those
                  generous people willing to spread their knowledge with those
                  aspiring students interested to learn for FREE.
                </p>
              </blockquote>
            </div>
            <div className="col s4 l2 offset-s4">
              <div className="row">
                <img src="img/DD.webp" alt="Debabrata Das" width="100%" />
              </div>
              <div className="row center">
                <a
                  className="founder-name"
                  href="https://www.linkedin.com/in/debabrata-dd/"
                  target="_blank"
                  rel="noopener"
                >
                  Debabrata Das
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vision;
