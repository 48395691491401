import React from "react";
import Subjects from "./Subjects";
// import { connect } from "react-redux";
// import { firestoreConnect } from "react-redux-firebase";
// import { compose } from "redux";

class SubjectList extends React.Component {
  componentDidMount() {
    const M = window.M;

    var elem = document.querySelectorAll("select");
    var instance = M.FormSelect.init(elem, null);
  }

  render() {
    return (
      <>
        <div className="input-field">
          <select
            id="subject"
            required
            // className="browser-default"
            //value={this.state.value}
            onChange={this.props.onChange}
            defaultValue=""
          >
            <option value="" disabled>
              Select a subject
            </option>
            {Subjects &&
              Subjects.map((subject) => {
                return (
                  <option
                    value={subject.name}
                    data-icon={process.env.PUBLIC_URL + subject.thumbnail}
                  >
                    {subject.name}
                  </option>
                );
              })}
          </select>
          <label>Session Subject</label>
        </div>
      </>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     subjects: state.firestore.ordered.subjects,
//   };
// };

// export default compose(
//   connect(mapStateToProps),
//   firestoreConnect(() => {
//     return [
//       {
//         collection: "subjects",
//         orderBy: ["subjectName"],
//       },
//     ];
//   })
// )(SubjectList);

export default SubjectList;
