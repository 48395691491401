import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import "materialize-css/dist/css/materialize.min.css";
// import M from "materialize-css/dist/js/materialize.min.js";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import Dashboard from "./components/dashboard/Dashboard";
import SessionDetails from "./components/sessions/SessionDetails";
import Navbar from "./components/layout/Navbar";
import CreateSession from "./components/teacher/CreateSession";
import TeacherDashboard from "./components/teacher/TeacherDashboard";
import StudentDashboard from "./components/student/StudentDashboard";
import SessionsAvailable from "./components/student/SessionsAvailable";
import EnrolledSessionDetails from "./components/student/EnrolledSessionDetails";
import ForgotPassword from "./components/auth/ForgotPassword";

function App() {
  // useEffect(() => {
  // const M = window.M;
  // let sidenav = document.querySelector("#mobile-links");
  // M.Sidenav.init(sidenav, {});
  // var elems = document.querySelectorAll(".modal");
  // var instances = M.Modal.init(elems, {});
  // }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />

        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route path="/session/:id" component={SessionDetails} />
          <Route
            path="/enrolledsession/:id"
            component={EnrolledSessionDetails}
          />
          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/teacher" component={TeacherDashboard} />
          <Route path="/student" component={StudentDashboard} />
          <Route path="/sessions/create" component={CreateSession} />
          <Route path="/sessions" component={SessionsAvailable} />
          <Route component={Dashboard} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
