import React, { Component } from "react";
import "./Dashboard.css";
import Vision from "./Vision";
import Footer from "../layout/Footer";
import HomeSections from "./HomeSections";
import Metrics from "./Metrics";
import Testimonial from "./Testimonial";
import Videos from "./Videos";
import Banner from "./Banner";

class Dashboard extends Component {
  render() {
    return (
      <>
        <Banner />
        <HomeSections />
        <Metrics />
        <Vision />
        <Testimonial />
        <Videos />
        <Footer />
      </>
    );
  }
}

export default Dashboard;
