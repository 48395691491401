import React from "react";

const SessionContent = (props) => {
  return (
    <div className="row">
      <div className="col s12 l12">
        {props.content.split("<br/>").map(function (item, i) {
          return (
            <span key={i}>
              {item}
              <br />
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default SessionContent;
