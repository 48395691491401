import React, { Component } from "react";
import { connect } from "react-redux";
import { createSession } from "../../store/actions/sessionActions";
import { Redirect } from "react-router-dom";
import momentTZ from "moment-timezone";
import SubjectList from "../sessions/SubjectList";
import StudentTypes from "../sessions/StudentTypes";

class CreateSession extends Component {
  state = {
    title: "",
    sessionDate: "",
    sessionTime: "",
    duration: "40",
    timeZone: momentTZ.tz.guess(),
    subject: "",
    content: "",
    // studentTypes: "",
  };

  componentDidMount() {
    const M = window.M;

    var elem = document.querySelectorAll("select");
    var instance = M.FormSelect.init(elem, null);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const M = window.M;
    var elem = document.querySelector("#studentTypes");
    var instance = M.FormSelect.getInstance(elem);
    var studentTypes = instance.getSelectedValues().toString();

    this.props.createSession(this.state, studentTypes);
    this.props.history.push("/teacher");
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.props.history.push("/teacher");
  };

  render() {
    const { auth } = this.props;

    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className="row">
        <div className="col s12 l5 offset-l3">
          <div className="card">
            <div className="container">
              <form
                onSubmit={this.handleSubmit}
                className="white"
                autoComplete="off"
              >
                <h5 className="teal-text text-lighten-1 center">
                  Schedule a Session
                </h5>
                <div className="input-field">
                  <label htmlFor="title">
                    Title of your session (upto 40 characters)
                  </label>
                  <input
                    type="text"
                    id="title"
                    required
                    autoFocus
                    maxLength="40"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="input-field">
                  <label htmlFor="sessionDate">Date</label>
                  <input
                    type="date"
                    id="sessionDate"
                    required
                    onChange={this.handleChange}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="sessionTime">
                    Session Time ({momentTZ.tz.guess()})
                  </label>
                  <input
                    type="time"
                    id="sessionTime"
                    required
                    onChange={this.handleChange}
                  />
                </div>
                <div className="input-field">
                  <select
                    id="duration"
                    required
                    // className="browser-default"
                    //value={this.state.value}
                    onChange={this.handleChange}
                    defaultValue="40"
                  >
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="60">60</option>
                  </select>
                  <label>Session Duration (in minutes)</label>
                </div>
                <SubjectList onChange={this.handleChange} />
                <div className="input-field">
                  <select multiple id="studentTypes" required>
                    {StudentTypes &&
                      StudentTypes.map((student) => {
                        return (
                          <option
                            value={student.value}
                            data-icon={
                              process.env.PUBLIC_URL + student.thumbnail
                            }
                          >
                            {student.name}
                          </option>
                        );
                      })}
                  </select>
                  <label>Student Types</label>
                </div>
                <div className="input-field">
                  <label htmlFor="content">
                    Session Content (upto 512 characters)
                  </label>
                  <textarea
                    id="content"
                    className="materialize-textarea"
                    required
                    maxLength="512"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="meetingLink">
                    Meeting Link (like, Google Meet, Zoom, etc.)
                  </label>
                  <input
                    type="text"
                    id="meetingLink"
                    required
                    onChange={this.handleChange}
                  />
                </div>
                <div className="row">
                  <div className="col s12 m6 l6">
                    <button className="btn waves-effect waves-light teal lighten-1 width100">
                      Create
                    </button>
                  </div>
                  <div className="col s12 m6 l6">
                    <button
                      onClick={this.handleCancel}
                      className="btn waves-effect waves-light blue-grey darken-1 width100"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createSession: (session, studentTypes) =>
      dispatch(createSession(session, studentTypes)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSession);
