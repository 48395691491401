import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signUp } from "../../store/actions/authActions";
import Recaptcha from "react-recaptcha";

class SignUp extends Component {
  state = {
    isVerified: false,
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    isHuman: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.isVerified) {
      this.props.signUp(this.state);
      this.props.history.push("/student");
    } else {
      this.setState({
        isHuman: "Please verify that you are a human!",
      });
      // console.log(this.state.authError);
    }
  };

  recaptchLoaded = () => {
    //console.log("Recaptch loaded successfully!");
  };

  verifyCallback = (res) => {
    if (res) {
      this.setState({
        isHuman: "",
        isVerified: true,
      });
    }
  };

  render() {
    const { auth, authError } = this.props;
    if (auth.uid) return <Redirect to="/" />;

    return (
      <div className="row">
        <div className="col s12 l5 offset-l3">
          <div className="card">
            <div className="container">
              <form
                onSubmit={this.handleSubmit}
                className="white"
                autoComplete="off"
              >
                <h5 className="teal-text text-darken-1 center">
                  Welcome to freedemy.host
                </h5>
                <div className="input-field">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    required
                    onChange={this.handleChange}
                    autoFocus
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    required
                    onChange={this.handleChange}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    required
                    onChange={this.handleChange}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    required
                    onChange={this.handleChange}
                  />
                </div>
                <Recaptcha
                  sitekey="6LfjJ6QaAAAAAFij2XxYOcviBQhnuC4eNVHwbYq8"
                  render="explicit"
                  verifyCallback={this.verifyCallback}
                  onloadCallback={this.recaptchLoaded}
                />
                <div className="input-field center">
                  <div className="row">
                    <div className="col s12 l6 offset-l3">
                      <button className="waves-effect waves-light btn teal darken-1 width100">
                        {/* <i className="material-icons small left">person_add</i> */}
                        Sign up
                      </button>
                    </div>
                  </div>
                  <div>
                    <span className="">Already have an account? </span>
                    <NavLink to="/signin">Log in</NavLink>
                  </div>

                  <div className="red-text center">
                    {this.state.isHuman.length > 0 ? (
                      <p>{this.state.isHuman}</p>
                    ) : null}
                    {authError}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const err = state.auth.authError;
  state.auth.authError = null;
  return {
    auth: state.firebase.auth,
    authError: err,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
