import React, { Component } from "react";
// import SessionList from "../sessions/SessionList";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { NavLink, Redirect } from "react-router-dom";
import EnrolledSessions from "./EnrolledSessions";
// import { AiOutlineRight } from "react-icons/ai";

class StudentDashboard extends Component {
  // componentDidMount() {
  //   const M = window.M;

  //   var elem = document.querySelectorAll("select");
  //   var instance = M.Tabs.init(elem, null);
  // }

  render() {
    const { enrollments, auth } = this.props;

    if (!auth.uid) return <Redirect to="/signin" />;

    const getEnrollements = () => {
      if (enrollments && enrollments.length === 0) {
        return (
          <div className="grey-text">
            {"You haven't enrolled for any session yet! "}
            <NavLink to="/sessions">click here</NavLink> to find a session
          </div>
        );
      } else {
        const enrollmentsFuture =
          enrollments &&
          enrollments.filter((x) => x.sessionDateTime.toDate() >= new Date());

        const enrollmentsPast =
          enrollments &&
          enrollments
            .filter((x) => x.sessionDateTime.toDate() < new Date())
            .sort((a, b) => {
              return b.sessionDateTime.toDate() - a.sessionDateTime.toDate();
            });

        return (
          <div className="col s12 l12">
            <h6>Upcoming:</h6>
            {enrollmentsFuture && enrollmentsFuture.length > 0 ? (
              <EnrolledSessions enrollments={enrollmentsFuture} />
            ) : (
              <span className="grey-text">
                {
                  "- Nothing, however you can enroll for any upcoming session by clicking FIND A SESSION button above."
                }
              </span>
            )}
            <h6>Completed:</h6>
            {enrollmentsPast && enrollmentsPast.length > 0 ? (
              <EnrolledSessions enrollments={enrollmentsPast} />
            ) : (
              <span className="grey-text">
                {"- Nothing. It will display the sessions when it's completed."}
              </span>
            )}
          </div>
        );
      }
    };

    return (
      <div className="container">
        <div className="row">
          <div className="col s12 l12">
            <blockquote className="flow-text">
              As a <span className="text-bold">Student</span>, you can learn
              anything of your interest online for FREE
            </blockquote>
            <ul>
              <li className="margin-top-10">
                <i className="material-icons small left">chevron_right</i>
                click <span className="text-bold">FIND A SESSION</span> button
              </li>
              <li className="margin-top-10">
                <i className="material-icons small left">chevron_right</i>
                select a session of your interest and click{" "}
                <span className="text-bold">ENROLL</span> button
              </li>
              <li className="margin-top-10">
                <i className="material-icons small left">chevron_right</i>
                once enrolled, you will see them under{" "}
                <span className="text-bold">Your Enrolled Sessions</span>{" "}
                section
              </li>
              <li className="margin-top-10">
                <i className="material-icons small left">chevron_right</i>
                <span className="text-bold">attend</span> the session at the
                scheduled date and time
              </li>
            </ul>
            <div className="row">
              <div className="col s12 m6 offset-m3 l4 offset-l4">
                <div className="input-field">
                  <NavLink
                    to="/sessions"
                    className="btn waves-effect waves-light teal lighten-1 width100"
                  >
                    {/* <i className="material-icons small left">search</i> */}
                    Find a Session
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <h5>Your Enrolled Sessions</h5>
          <hr />
          {getEnrollements()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    enrollments: state.firestore.ordered.enrolledSessions,
    auth: state.firebase.auth,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (!props.auth.uid) return [];

    return [
      {
        collection: "enrollments",
        where: ["userId", "==", props.auth.uid],
        orderBy: ["sessionDateTime"],
        storeAs: "enrolledSessions",
      },
    ];
  })
)(StudentDashboard);
