import React from "react";

const quotes = [
  {
    quote: "All I want is an education, and I am afraid of no one.",
    by: "Malala Yousafzai",
    bgcolor: "#FF6464",
  },
  {
    quote: "Teaching is the highest form of understanding.",
    by: "Aristotle",
    bgcolor: "#91C483",
  },
  {
    quote: "When one teaches, two learn.",
    by: "Robert Heinlein",
    bgcolor: "#FFE162",
  },
  {
    quote: "I am not a teacher, but an awakener.",
    by: "Robert Frost",
    bgcolor: "#FFCDDD",
  },
  {
    quote:
      "The true teacher defends his pupils against his own personal influence.",
    by: "Amos Bronson Alcott",
    bgcolor: "#96CEB4",
  },
  {
    quote:
      "In a completely rational society, the best of us would be teachers and the rest of us would have to settle for something else.",
    by: "Lee Iacocca",
    bgcolor: "#AEFEFF",
  },
  {
    quote:
      "The mediocre teacher tells. The good teacher explains. The superior teacher demonstrates. The great teacher inspires.",
    by: "William Arthur Ward",
    bgcolor: "#FFCBCB",
  },
  {
    quote: "The best teachers are the ones that change their minds.",
    by: "Terry Heick",
    bgcolor: "#FFF89A",
  },
  {
    quote:
      "A good teacher is like a candle – it consumes itself to light the way for others.",
    by: "Mustafa Kemal Atatürk",
    bgcolor: "#FEECE9",
  },
  {
    quote:
      "A great teacher can teach Calculus with a paper clip and literature in an empty field. Technology is just another tool, not a destination.",
    by: "Unknown",
    bgcolor: "#D6E5FA",
  },
  {
    quote: "Teaching is the one profession that creates all other professions.",
    by: "Unknown",
    bgcolor: "#CDDEFF",
  },
  {
    quote:
      "The job of an educator is to teach students to see vitality in themselves.",
    by: "Joseph Campbell",
    bgcolor: "#FFF1BD",
  },
  {
    quote:
      "Teaching is only demonstrating that it is possible. Learning is making it possible for yourself.",
    by: "Paulo Coelho",
    bgcolor: "#B1D0E0",
  },
  {
    quote:
      "Teachers have three loves: love of learning, love of learners, and the love of bringing the first two loves together.",
    by: "Scott Hayden",
    bgcolor: "#EDD2F3",
  },
  {
    quote:
      "You can teach a person all you know, but only experience will convince him that what you say is true.",
    by: "Richelle E. Goodrich",
    bgcolor: "#FFEBCC",
  },
  {
    quote: "It takes a big heart to help shape little minds.",
    by: "Unknown",
    bgcolor: "#EED7CE",
  },
  {
    quote:
      "It is the supreme art of the teacher to awaken joy in creative expression and knowledge.",
    by: "Albert Einstein",
    bgcolor: "#F7F7F7",
  },
];
const Banner = () => {
  const d = new Date();
  let day = d.getDate();
  let index = day % quotes.length;
  return (
    <div>
      <marquee bgcolor={quotes[index].bgcolor}>
        "{quotes[index].quote}" - {quotes[index].by} | Have a wonderful day!
      </marquee>
    </div>
  );
};

export default Banner;
