import React, { useEffect, Component } from "react";

class ModalYesNo extends Component {
  componentDidMount() {
    const M = window.M;

    var elem = document.querySelector(".modal");
    var instance = M.Modal.init(elem, {
      startingTop: "50%",
      inDuration: 250,
    });
  }

  render() {
    return (
      <div id="modal1" className="modal">
        <div className="modal-content">
          <h4>{this.props.title}</h4>
          <p>{this.props.message}</p>
        </div>
        <div className="modal-footer">
          <a
            href="#!"
            className="modal-close waves-effect waves-green btn-flat"
          >
            No
          </a>
          <a
            href="#!"
            className="modal-close waves-effect red waves-green btn-flat"
            onClick={this.props.handleConfirm}
          >
            Yes
          </a>
        </div>
      </div>
    );
  }
}

export default ModalYesNo;
