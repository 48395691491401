import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../store/actions/authActions";

const SingnedInLinks = (props) => {
  const { profile } = props;

  return (
    <>
      <ul className="right hide-on-med-and-down">
        <li>
          <NavLink to="/student" activeClassName="active-menu">
            Student
          </NavLink>
        </li>
        <li>
          <NavLink to="/teacher" activeClassName="active-menu">
            Teacher
          </NavLink>
        </li>
        <li>
          <a onClick={props.signOut}>Log out</a>
        </li>
        <li>
          <div className="tooltip">
            <NavLink to="/" className="btn btn-floating teal lighten-1 tooltip">
              {profile ? profile.initials : "NA"}
            </NavLink>
            <span className="tooltiptext teal-text text-accent-3">
              {profile.firstName} {profile.lastName}
            </span>
          </div>
        </li>
        {/* <li>
        <i className="material-icons amber-text medium notification">
          notifications
        </i>
      </li> */}
        {/* <li>
        <span>
          Welcome {profile.lastName}, {profile.firstName}
        </span>
      </li> */}
      </ul>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(null, mapDispatchToProps)(SingnedInLinks);
