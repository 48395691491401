const initState = {
  sessions: [],
};

const sessionReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_SESSION":
      console.log("created session", action.session);
      return state;
    case "CREATE_SESSION_ERROR":
      console.log("create project error", action.err);
      return state;
    case "UPDATE_SESSION":
      console.log("Update session successful!");
      return state;
    case "UPDATE_SESSION_ERROR":
      console.log("Error: ", action.err);
      break;
    case "ENROLL_SESSION":
      console.log("Enroll successful!");
      return state;
    case "ENROLL_SESSION_ERROR":
      console.log("Error: ", action.err);
      break;
    case "CANCEL_ENROLLED_SESSION":
      console.log("Cancelled enroll session successful!");
      return state;
    case "CANCEL_ENROLLED_SESSION_ERROR":
      console.log("Error: ", action.err);
      break;
    case "THUMBS_UP":
      console.log("THUMBS UP");
      return state;
    case "THUMBS_UP_ERROR":
      console.log("Error: ", action.err);
      break;
    case "THUMBS_DOWN":
      console.log("THUMBS DOWN");
      return state;
    case "THUMBS_DOWN_ERROR":
      console.log("Error: ", action.err);
      break;
    default:
      return state;
  }
};

export default sessionReducer;
